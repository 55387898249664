<template>
  <AccountUnverified v-if="!user.email_verified_at" />
  <div class="teammates pr-0 pl-0 col-md-6 pl-0" v-else-if="user.subscription_plan.teammates">
    <div class="email-list">
        <TeammateItem v-for="(teammate,index) in  teammates" :key="index"  :teammate="teammate"/>
    </div>
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
    <form ref="form"
          @submit.prevent="passes(onFormSubmit)" v-if="user.subscription_plan.teammates !== null && (user.limits ? user.limits.teammates >= teammates.length : user.subscription_plan.teammates >= teammates.length)">
      <div class="new-email d-flex">
        <base-textbox ref="newEmail"
                      placeholder="Email Address"
                      rules="email"
                      v-model="newEmail"
                      class="email-field flex-fill"/>
        <base-dropdown class="role-select"
                       item-label="title"
                       :searchable="false"
                       track-by="type"
                       v-model="selectedRole"
                       :options="roles" />
        <base-button class="add"
                     color="primary"
                     :disabled="emailAlreadyAdded || !selectedRole || !newEmail || invalid"
                     :loading="submitting"
                     @click.prevent="onFormSubmit">Add Email
        </base-button>
      </div>
      <p class="already-added"
         v-if="emailAlreadyAdded">This email is already added</p>

      <base-alert v-if="error" type="error">
        We could not add your teammate. {{alreadyHasAnAccount}}
      </base-alert>
    </form>
    </ValidationObserver>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Upgrade your plan to access this feature."/>
    </div>
  </div>
</template>

<script>

import BaseAlert from '../../Base/BaseAlert'
import TeammateItem from './TeammateItem'
import UpgradeBadge from '@/components/Common/UpgradeBadge'

export default {
  components: { TeammateItem, BaseAlert, UpgradeBadge },
  data () {
    return {
      newEmail: '',
      emailAlreadyAdded: false,

      submitting: false,
      deleting: false,

      roles: [
        {
          type: 'viewer',
          title: 'Viewer'
        },
        {
          type: 'editor',
          title: 'Editor'
        },
        {
          type: 'accountant',
          title: 'Accountant'
        }
      ],

      selectedRole: null,
      error: false,
      alreadyHasAnAccount: ''
    }
  },

  methods: {
    async onFormSubmit () {
      this.error = false
      if (this.emailAlreadyAdded) {
        return false
      }

      this.submitting = true
      const data = {
        email: this.newEmail,
        role: this.selectedRole.type
      }
      try {
        await this.$store.dispatch('teammates/addTeammate', data)
      } catch (error) {
        this.error = true
        if (error.response.status === 422) {
          this.alreadyHasAnAccount = 'There is already an account registered with this email.'
        }
      }

      this.submitting = false

      this.newEmail = ''
      this.selectedRole = null
    },

    async deleteTeammate (teammate) {
      this.deleting = true
      await this.$store.dispatch('teammates/deleteTeammate', teammate.id)
      this.deleting = false
    }

    // async deleteTeammate (teammate) {
    //   this.deleting = true
    //   await this.$store.dispatch('teammates/deleteTeammate', teammate.id)
    //   this.deleting = false
    // }
  },

  computed: {
    ...mapState('teammates', ['teammates']),
    ...mapState('authentication', ['user'])
  },

  created () {
    if (this.user.email_verified_at) this.$store.dispatch('teammates/load')
  },

  watch: {
    newEmail: {
      handler: function (newEmail) {
        this.emailAlreadyAdded = false

        this.teammates.forEach(teammate => {
          if (teammate.email === newEmail) {
            this.emailAlreadyAdded = true
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .teammates {
    //max-width: 550px;

    .new-email {
      display: flex;

      @media (max-width: 1200px) {
        .email-field {
          flex: 0;
          ::v-deep {
            .input {
              width: 195px;
              @media (max-width: 530px) {
                width: 100%;
              }
            }
          }
        }
      }

      @media (max-width: 530px) {
        flex-direction: column;
      }

      .add {
        margin: 0 0 0 10px;
        @media (max-width: 530px) {
          margin: 0;
        }
      }
      .role-select {
        margin-left: 10px;
        width: 130px;
        @media (max-width: 530px) {
          width: 100%;
          margin-left: 0;
        }
        ::v-deep {
          .multiselect {
            width: 130px;
            @media (max-width: 530px) {
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
      .role-select.is-open{
        width: 130px;
        @media (max-width: 530px) {
          width: 100%;
        }
      }
    }

    .email-list {
      max-width: 400px;
      margin-bottom: 10px;

      .email {
        display: flex;
        align-items: center;

        .remove {
          cursor: pointer;
          padding: 10px 10px 10px 0;

          &:hover ::v-deep svg g {
            transition: 0.2s all ease;
            fill: map-get($colors, purple-1) !important;
          }
        }

        .prevent-click {
         pointer-events: none;
        }

        .text {
          margin-bottom: 3px;
        }
      }
    }
  }
  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    @media (max-width: 767px) {
      margin: 24px 0;
      min-height: unset !important;
    }
  }
</style>

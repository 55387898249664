<template>
    <div class="TeammateItem">
      <div class="email">
        <div class="remove" v-bind:class="{'prevent-click': deleting}" @click.prevent="deleteTeammate()" v-if="teammate.id !== user.id">
          <icon-trash width="18"
                      height="18" />
        </div>
        <div class="text d-flex align-items-center">
          <div class="mr-2">
            {{ teammate.email }}
          </div>

          <base-dropdown class="role-select mb-0"
                         item-label="title"
                         :searchable="false"
                         :allowEmpty="false"
                         track-by="type"
                         v-model="selectedRole"
                         :options="roles" v-if="teammate.id !== user.id && this.selectedRole" />
          <base-button class="add"
                       color="primary"
                       :loading="updating"
                       @click.prevent="updateTeammate" v-if="teammate.id !== user.id" >Update</base-button>
        </div>
      </div>
      <base-alert type="error" v-if="error">Something went wrong</base-alert>
    </div>
</template>

<script>
import usersApi from '../../../api/usersApi'
import BaseAlert from '../../Base/BaseAlert'
import Swal from 'sweetalert2'

export default {
  name: 'TeammateItem',
  components: { BaseAlert },
  props: {
    teammate: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      deleting: false,
      updating: false,
      selectedRole: null,
      roles: [
        { type: 'accountant', title: 'Accountant' },
        { type: 'editor', title: 'Editor' },
        { type: 'viewer', title: 'Viewer' }
      ],
      error: false
    }
  },

  created () {
    this.selectedRole = this.roles.find(
      role => role.type === this.teammate.role
    )
  },

  methods: {
    async deleteTeammate () {
      this.deleting = true
      await this.$store.dispatch('teammates/deleteTeammate', this.teammate.id)
      this.deleting = false
    },

    updateTeammate: async function () {
      this.error = false
      this.updating = true
      if (this.selectedRole) {
        try {
          const response = await usersApi.updateTeammate(this.teammate.id, this.selectedRole.type)
          await this.$store.dispatch('teammates/updateTeammate', response.data)
          this.showSuccessMessage()
        } catch {
          this.error = true
          this.selectedRole = this.roles.find(
            role => role.type === this.teammate.role
          )
        }
        this.updating = false
      }
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'Your teammate has been saved',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }

  // watch: {
  //   selectedRole: {
  //     handler: function () {
  //       if (this.selectedRole && this.selectedRole.value !== this.teammate.role) {
  //         this.updateTeammate()
  //       }
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.TeammateItem {
  .email {
    display: flex;
    align-items: center;

    .remove {
      cursor: pointer;
      padding: 10px 10px 10px 0;

      &:hover ::v-deep svg g {
        transition: 0.2s all ease;
        fill: map-get($colors, purple-1) !important;
      }
    }

    .prevent-click {
      pointer-events: none;
    }

    .text {
      margin-bottom: 3px;
    }

    .role-select {
      margin-left: 10px;
      margin-right: 10px;
      width: 130px;
    }
    .role-select.is-open{
      width: 130px;
    }
  }
}
</style>
